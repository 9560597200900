@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: -0.5px;
  line-height: 1.5;
  font-size: 14px;
  background-color: #ddd;
  color: #343a40;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.bg-red {
  background-color: #c81f21;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.form-control,
.card,
.btn {
  border-radius: 0;
}

.form-control::-webkit-input-placeholder {
  font-size: 12px;
}

.form-control:-ms-input-placeholder {
  font-size: 12px;
}

.form-control::placeholder {
  font-size: 12px;
}

.bm-subtitle {
  font-weight: bold;
  text-align: center;
}

.profile-button {
  font-weight: 900;
  width: 100%;
  max-width: 200px;
  height: 35px;
}

/* vs-title */
.vs-title {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.18;
  color: #2e2f36;
  margin: 0 0 5px 0;
  line-height: 1;
}

.vs-subtitle {
  color: #888;
  font-weight: 400;
  line-height: 1.47;
  -webkit-letter-spacing: -1.7px;
  -moz-letter-spacing: -1.7px;
  -ms-letter-spacing: -1.7px;
  letter-spacing: -1.7px;
  font-size: 15px;
}

@media (max-width: 768px) {
  vs-title {
    font-size: 20px;
  }
  
  .vs-subtitle {
    color: #888;
    font-size: 15px;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    right: -200px;
  }
  100% {
    opacity: 1;
    right: -150px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    right: -200px;
  }
  100% {
    opacity: 1;
    right: -150px;
  }
}



